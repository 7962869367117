export const items = [
  {
    role: 'student',
    title: 'Lernende',
    icon: 'mdi-account',
    group: 'student',
    children: [
      {
        title: 'Noten',
        group: 'student',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks',
          },
          /*{
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks',
          },
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks',
          },*/
          {
            title: 'Prüfungsnoten',
            icon: 'mdi-circle-medium',
            to: 'final-marks',
          },
        ],
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'timetable',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },

  //   {
  //     subheader: "Apps",
  //     icon: "mdi-apps-box",
  //     divider: true
  //   },
  {
    role: 'admin',
    title: 'AMA',
    icon: 'mdi-account-star',
    group: 'admin',
    children: [ 
      {
        title: 'Identität',
        icon: 'mdi-chevron-right',
        to: 'impersonation',
      },
      {
        title: 'PMA Liste',
        icon: 'mdi-chevron-right',
        to: 'teachers',
      },
      {
        title: 'Lernende',
        icon: 'mdi-chevron-right',
        to: 'students',
      },
    ],
  },
  {
    role: 'parent',
    title: 'Eltern',
    icon: 'mdi-account-supervisor-circle',
    group: 'parent',
    children: [
      {
        title: 'Kinder',
        icon: 'mdi-chevron-right',
        to: 'children',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },
  {
    role: 'company',
    title: 'Praktikumsbetriebe',
    icon: 'mdi-domain',
    group: 'company',
    children: [
      {
        title: 'Praktikanten ',
        icon: 'mdi-chevron-right',
        to: 'interns',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
      {
        title: 'Praxisnoten',
        icon: 'mdi-chevron-right',
        to: 'intern-marks',
      },
    ],
  },

  {
    role: 'teacher',
    title: 'PMA',
    icon: 'mdi-account-tie',
    group: 'teacher',
    children: [
      {
        title: 'Noten',
        group: 'teacher',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks',
          },
          {
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks',
          },/*
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks',
          },*/
          {
            title: 'Noteneingabe',
            icon: 'mdi-circle-medium',
            to: 'final-marks',
          },
        ],
      },
      {
        title: 'Identität',
        icon: 'mdi-chevron-right',
        to: 'impersonation',
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'timetable',
      },
      {
        title: 'Verfügbarkeiten',
        icon: 'mdi-chevron-right',
        to: 'availability',
      },
      {
        title: 'Pensum',
        icon: 'mdi-chevron-right',
        to: 'workload',
      },
      {
        title: 'Abwesenheiten',
        icon: 'mdi-chevron-right',
        to: 'absence',
      },
      {
        title: 'Klassen',
        icon: 'mdi-chevron-right',
        to: 'courses',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
    ],
  },
  {
    role: 'teacher',
    title: 'Lernende (PMA)',
    icon: 'mdi-account',
    group: 'student',
    children: [
      {
        title: 'Noten',
        group: 'student',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks',
          },
          {
            title: 'Prüfungsnoten',
            icon: 'mdi-circle-medium',
            to: 'final-marks',
          },
        ],
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'timetable',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },
]
